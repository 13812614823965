import request from '@/until/request.js'
const prefixName = '/yny/webFiaRpt'

// 获取月结存列表
export function getBalance(data){
  return request({
    url: '/yny/balance',
    method: 'get',
    params: { ...data }
  })
}
// 设置月结存
export function setBalance(data){
  return request({
    url: '/yny/balance',
    method: 'put',
    data: data
  })
}
// 查询往来单位应收应付表
export function getUnitAll(data){
  return request({
    url: prefixName + '/unitAll',
    method: 'get',
    params: { ...data }
  })
}
// 查询应付明细表
export function getSupDetail(data){
  return request({
    url: prefixName + '/supDetail',
    method: 'get',
    params: { ...data }
  })
}
// 查询应收明细表
export function getCusDetail(data){
  return request({
    url: prefixName + '/cusDetail',
    method: 'get',
    params: { ...data }
  })
}
// 查询账户余额表
export function getAut(data){
  return request({
    url: prefixName + '/aut',
    method: 'get',
    params: { ...data }
  })
}
// 查询账户明细表
export function getAutDetail(data){
  return request({
    url: prefixName + '/autDetail',
    method: 'get',
    params: { ...data }
  })
}